import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Metatags from "../components/metatags"





function Page404() {
  return (
    <>
      <Metatags
        title="Multilogica | Tutorial"
        description="Tutoriais da Multilógica-shop"
        url="https://tutoriais.multilogica-shop.com"
        image="https://tutoriais.multilogica-shop.com/Multilogica-shop.png"
        keywords="eletrônica, arduino, micro:bit, raspberry pi, sparkfun, pololu"
      />
      <Navbar />
      <div className="height-40" />
      <div>
        <Container className="info-page">
          <Row className="align-items-center">
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <div className="msg">
                <h1>404</h1>
                <h3>Página não encontrada</h3>
              </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>

            </Col>
          </Row>
        </Container>
      </div>
      <div className="height-40" />
      <Footer /> 
    </>
  )
}

export default Page404
